import { i18n } from '@/i18n'
import type { Document } from '@/modules/shared/domain/document/document'
import type { AxiosInstance } from 'axios'
import type { Property } from '../domain/property'
import type { PropertyBasicInformation } from '../domain/propertyBasicInformation'
import type { CountryState, PropertyCoreInformation } from '../domain/propertyCoreInformation'
import type { PropertyDocuments } from '../domain/propertyDocuments'
import type { PropertyEquipmentInformation } from '../domain/propertyEquipmentInformation'
import type { PropertyImages } from '../domain/propertyImages'
import type { PricingPlan, PropertyPaymentInformation } from '../domain/propertyPaymentInformation'
import type { PropertyRepository } from '../domain/propertyRepository'
import {
  type PricingPlanDto,
  type PropertyApiDto,
  fromDto,
  fromPricingPlanDto,
  propertyBasicInformationToDto,
  propertyCoreInformationToDto,
  propertyEquipmentInformationToDto,
  toPricingPlanDto
} from './apiDto'
import { findCountryState } from './countryStateGeocode'

export function apiPropertyRepositoryBuilder(
  apiService: AxiosInstance,
  apiV2Service: AxiosInstance
): PropertyRepository {
  let cache: Nullable<Property> = null
  let cacheId: Nullable<string> = null

  async function uploadDocuments(id: string, type: string, documents: Document[]): Promise<void> {
    const filesToUpload = documents.filter(({ source }) => source !== null).map(({ source }) => source as File)

    for (const file of filesToUpload) {
      const formData = new FormData()
      formData.append('file', file)

      await apiV2Service.post(`/onboarding/properties/${id}/files/${type}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
    }
  }

  return {
    async add(userId: string) {
      const dto = (await apiService.post(`/properties-v2`, { landlord: userId })) as PropertyApiDto
      return fromDto(dto, null, null)
    },
    async deleteDocument(id: string, documentId: string) {
      cache = cacheId = null
      await apiService.delete(`/properties-v2/${id}/documents/${documentId}`)
    },
    async deleteImage(id: string, imageId: string) {
      cache = cacheId = null
      await apiService.delete(`/properties-v2/${id}/images/${imageId}`)
    },
    async get(id: string) {
      if (id === cacheId && cache !== null) {
        return cache
      }

      const dto = (await apiService.get(`/properties-v2/${id}`)) as PropertyApiDto

      let paymentPlan: Nullable<PricingPlan> = null
      try {
        const pricingPlanDto = (await apiV2Service.get(`/onboarding/properties/${id}/pricing-plan`)) as PricingPlanDto
        paymentPlan = fromPricingPlanDto(pricingPlanDto)
      } catch {}

      let state: Nullable<CountryState> = null
      if (dto.address.street) {
        try {
          state = await findCountryState(dto.address.street)
        } catch {}
      }

      cacheId = id
      cache = fromDto(dto, paymentPlan, state)
      return cache
    },
    async getAll(landlordId: string) {
      const dtos = (await apiService.get(`/landlords/${landlordId}/properties`)) as PropertyApiDto[]
      return dtos.map((dto) => fromDto(dto, null, null))
    },
    async getCatastralInformation(reference: string) {
      return apiService.get(`/properties-v2/catastro?ref=${reference}`)
    },
    async finishOnboarding(id: string) {
      cache = cacheId = null
      const lang = i18n.global.locale.value
      return apiService.post(`/properties-v2/${id}/finish-onboarding?lang=${lang}`)
    },
    async updateBasicInformation(id: string, basicInformation: PropertyBasicInformation): Promise<void> {
      await apiService.patch(`/properties-v2/${id}`, propertyBasicInformationToDto(basicInformation))
      if (cacheId === id && cache !== null) {
        cache.basicInformation = basicInformation
      }
    },
    async updateCoreInformation(id: string, coreInformation: PropertyCoreInformation): Promise<void> {
      cache = cacheId = null
      await apiService.patch(`/properties-v2/${id}`, propertyCoreInformationToDto(coreInformation))
    },
    async updateEquipmentInformation(id: string, equipmentInformation: PropertyEquipmentInformation): Promise<void> {
      await apiService.patch(`/properties-v2/${id}`, propertyEquipmentInformationToDto(equipmentInformation))
      if (cacheId === id && cache !== null) {
        cache.equipmentInformation = equipmentInformation
      }
    },
    async updateImages(id: string, images: PropertyImages): Promise<void> {
      cache = cacheId = null

      await apiService.patch(`/properties-v2/${id}`, { management: { needsPhotoService: images.needsPhotoService } })

      const filesToUpload = images.files.filter(({ source }) => source !== null).map(({ source }) => source as File)
      if (filesToUpload.length > 0) {
        const formData = new FormData()
        filesToUpload.forEach((file) => formData.append('files[]', file))

        await apiService.post(`/properties-v2/${id}/images`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
      }
    },
    async updateDocuments(id: string, documents: PropertyDocuments): Promise<void> {
      cache = cacheId = null

      await uploadDocuments(id, 'energy-cert', documents.energyCertificate)
      await uploadDocuments(id, 'habitability-cert', documents.habitabilityCertificate)
      await uploadDocuments(id, 'property-expense-bill', documents.propertyExpenses)
      await uploadDocuments(id, 'utility-bill', documents.utilityBill)
    },
    async updatePaymentInformation(id: string, paymentInformation: PropertyPaymentInformation): Promise<void> {
      if (paymentInformation.plan === null) return

      const pricingPlanId = toPricingPlanDto(paymentInformation.plan)
      await apiV2Service.put(`/onboarding/properties/${id}/pricing-plan`, {
        pricingPlanId
      })

      if (cacheId === id && cache !== null) {
        cache.paymentInformation = paymentInformation
      }
    }
  }
}
