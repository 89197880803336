<template>
  <Layout>
    <template #left>
      <SignupStepper :step="3" :showPropertyStep="user?.type === UserType.Landlord" />
    </template>
    <template #main>
      <div class="flex column gap-1">
        <div>
          <h2>{{ t('signup.welcome') }}</h2>
          <Title path="signup.personal.title" />
        </div>
        <template v-if="user">
          <InputField
            v-model="name"
            :error="errors['name']"
            :label="t('forms.name')"
            :placeholder="t('forms.namePlaceholder')"
            :trackEnterEvent="
              user?.type === UserType.Landlord
                ? AnalyticsEvent.LandlordSignupEnterName
                : AnalyticsEvent.TenantSignupEnterName
            "
            data-test-id="nameInput"
          />
          <InputField
            v-model="surname"
            :error="errors['surname']"
            :label="t('forms.surname')"
            :placeholder="t('forms.surnamePlaceholder')"
            :trackEnterEvent="
              user?.type === UserType.Landlord
                ? AnalyticsEvent.LandlordSignupEnterSurname
                : AnalyticsEvent.TenantSignupEnterSurname
            "
            data-test-id="surnameInput"
          />
          <PhoneInputField
            v-model="phone"
            :error="errors['phone']"
            :label="t('forms.phone')"
            :placeholder="t('forms.phonePlaceholder')"
            :trackEnterEvent="
              user?.type === UserType.Landlord
                ? AnalyticsEvent.LandlordSignupEnterPhone
                : AnalyticsEvent.TenantSignupEnterPhone
            "
          />
          <p v-if="errors['generic']" class="error-message center" data-test-id="genericError">
            {{ errors['generic'] }}
          </p>
          <Button
            class="mt-1"
            variant="secondary"
            :loading="loading"
            @click="onAddAccountInformation"
            data-test-id="addAccountInformationButton"
          >
            {{ t('common.continue') }}
          </Button>
        </template>
        <FormSkeleton v-else />
      </div>
    </template>
    <template #footer>
      <SignupStepper :step="3" mobile />
    </template>
  </Layout>
</template>
<script lang="ts" setup>
import { Button, FormSkeleton, InputField, Layout, PhoneInputField, SignupStepper, Title } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { addProperty } from '@/modules/property/application'
import { FormValidationError } from '@/modules/shared/domain/errors/formValidationError'
import { findCurrentUser, updateCurrentUser } from '@/modules/user/application'
import { UserType, type User } from '@/modules/user/domain/user'
import { router } from '@/router'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useAnalytics()

const name = ref<Nullable<string>>(null)
const surname = ref<Nullable<string>>(null)
const phone = ref<Nullable<string>>(null)
const loading = ref(false)
const errors = ref<Record<string, string>>({})
const user = ref<Nullable<User>>(null)

onMounted(async () => {
  user.value = await findCurrentUser()
  name.value = user.value?.name
  surname.value = user.value?.surname
  phone.value = user.value?.phone

  track(
    user.value.type === UserType.Landlord
      ? AnalyticsEvent.LandlordSignupStartPersonalData
      : AnalyticsEvent.TenantSignupStartPersonalData
  )
})

async function finishLandlordSignup() {
  const { id } = await addProperty()
  router.push({ name: 'propertyInformation', params: { id } })
}

async function finishTenantSignup() {
  router.push({ name: 'applications', query: { accountSuccess: 'true' } })
}

async function onAddAccountInformation() {
  if (!user.value) return

  loading.value = true
  errors.value = {}

  try {
    await updateCurrentUser({ name: name.value, phone: phone.value, type: user.value.type, surname: surname.value })
    if (user.value.type === UserType.Landlord) {
      track(AnalyticsEvent.LandlordSignupSubmitPersonalData)
      await finishLandlordSignup()
    } else {
      track(AnalyticsEvent.TenantSignupSubmitPersonalData)
      await finishTenantSignup()
    }
  } catch (error) {
    if (error instanceof FormValidationError) {
      errors.value = error.errors
    } else if (error instanceof Error) {
      errors.value = { generic: 'genericError.unexpected' }
    }
    loading.value = false
  }
}
</script>
