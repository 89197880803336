<template>
  <div class="card flex column gap-1" ref="card">
    <div class="flex justify-between align-center">
      <p class="title" v-if="isMainTenant">{{ t('tenant.mainTenant') }}</p>
      <p class="title" v-else>{{ t('tenant.coTenant') }} {{ index }}</p>
      <DeleteTenantButton v-if="!isMainTenant" :tenant="model" @deleted="emit('deleted')" />
    </div>
    <InputField
      v-model="model.name"
      :error="errors['name']"
      :label="`* ${t('forms.name')}`"
      :placeholder="t('forms.name')"
      type="text"
      data-test-id="nameInput"
    />
    <InputField
      v-model="model.surname"
      :error="errors['surname']"
      :label="`* ${t('forms.surname')}`"
      :placeholder="t('forms.surname')"
      type="text"
      data-test-id="surnameInput"
    />
    <template v-if="opened">
      <InputField
        :disabled="isMainTenant"
        name="email"
        v-model="model.email"
        :error="errors['email']"
        :label="`* ${t('forms.email')}`"
        :placeholder="t('forms.email')"
        type="email"
        data-test-id="emailInput"
      />
      <PhoneInputField
        v-model="model.phone"
        :error="errors['phone']"
        :label="t('forms.phone')"
        :placeholder="t('forms.phone')"
        data-test-id="phoneInput"
      />
      <p class="title">{{ t('tenant.employmentStatus') }}</p>
      <RadioButton
        v-model="model.employmentStatus"
        :options="employmentStatusOptions"
        name="employmentStatus"
        data-test-id="employmentStatusSelector"
      />
      <p class="light mb-1">{{ t('tenant.employmentStatusDescription') }}</p>
      <div v-if="model.employmentStatus">
        <p class="title">
          {{ t(`tenant.requiredDocumentation.${model.employmentStatus}`) }}
        </p>
        <template v-for="documentType in employmentStatusDocumentTypes[model.employmentStatus]">
          <p class="mb-05 mt-1">
            <b>{{ t(`tenant.documents.${documentType}`) }}</b>
          </p>
          <FileInput
            class="mb-2"
            v-model="model.documents[documentType]"
            type="file"
            mimeTypes="image/png,image/jpg,image/jpeg,application/pdf"
            :name="documentType + model.id"
            :maxFiles="10"
            :instructions="t('tenant.documents.uploadInstructions')"
            :data-test-id="`${documentType}FileInput`"
            @delete="onDeleteDocument(documentType, $event)"
          />
        </template>
      </div>
      <p v-if="errors['generic']" class="error-message center" data-test-id="genericError">{{ errors['generic'] }}</p>
      <Button
        variant="secondary"
        :disabled="!hasPendingChanges"
        :loading="loading"
        @click="onSave"
        data-test-id="saveButton"
      >
        {{ t('common.save') }}
      </Button>
    </template>
    <TenantCardToggle :opened="opened" :tenant="model" @toggle="opened = !opened" />
  </div>
</template>
<script lang="ts" setup>
import {
  Button,
  DeleteTenantButton,
  FileInput,
  InputField,
  PhoneInputField,
  RadioButton,
  TenantCardToggle
} from '@/components'
import type { Document } from '@/modules/shared/domain/document/document'
import { FormValidationError } from '@/modules/shared/domain/errors/formValidationError'
import { deleteTenantDocument, updateTenant, updateTenantDocuments } from '@/modules/tenant/application'
import { isTenantDocumentationCompleted, type Tenant } from '@/modules/tenant/domain/tenant'
import { employmentStatusDocumentTypes, TenantDocumentType } from '@/modules/tenant/domain/tenantDocuments'
import { TenantEmploymentStatus } from '@/modules/tenant/domain/tenantEmploymentStatus'
import { asyncForEach } from '@/utils/array'
import { pendingChangesCheckerBuilder } from '@/utils/pendingChangesChecker'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const model = defineModel<Tenant>({ required: true })

defineProps<{ index?: number; isMainTenant: boolean }>()

defineExpose({ save })

const emit = defineEmits(['deleted'])

const { t } = useI18n()

let documentIdsToDelete: string[] = []
const card = ref<Nullable<HTMLElement>>(null)
const loading = ref(false)
const errors = ref<Record<string, string>>({})
const opened = ref(!isTenantDocumentationCompleted(model.value))
const pendingChangesChecker = pendingChangesCheckerBuilder(model.value)
const hasPendingChanges = computed(() => pendingChangesChecker.hasPendingChanges(model.value))

const employmentStatusOptions = Object.values(TenantEmploymentStatus).map((value) => ({
  value,
  label: `tenant.employmentStatuses.${value}`
}))

async function onSave() {
  try {
    await save()
  } catch {}
}

async function onDeleteDocument(documentType: TenantDocumentType, documentToDelete: Document) {
  model.value.documents[documentType] = model.value.documents[documentType].filter(
    (document) => document !== documentToDelete
  )
  if (documentToDelete.id) {
    documentIdsToDelete.push(documentToDelete.id)
  }
}

function scrollIntoView() {
  if (card.value?.scrollIntoView) {
    card.value.scrollIntoView({ behavior: 'smooth' })
  }
}

async function save() {
  if (!hasPendingChanges.value) return

  loading.value = true
  errors.value = {}

  try {
    await updateTenant(model.value.id, {
      email: model.value.email,
      name: model.value.name,
      surname: model.value.surname,
      phone: model.value.phone,
      employmentStatus: model.value.employmentStatus
    })
    asyncForEach(documentIdsToDelete, (documentId) => deleteTenantDocument(model.value.id, documentId))
    model.value.documents = await updateTenantDocuments(model.value.id, model.value.documents)
    pendingChangesChecker.setLatestChanges(model.value)
    documentIdsToDelete = []
    loading.value = false
  } catch (error) {
    scrollIntoView()
    if (error instanceof FormValidationError) {
      errors.value = error.errors
    } else if (error instanceof Error) {
      errors.value = { generic: 'genericError.unexpected' }
    }
    loading.value = false
    throw error
  }
}
</script>
