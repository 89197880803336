<template>
  <ApplicationUpdatedModal />
  <template v-if="applications">
    <TenantAccountSuccessModal v-if="applications.length > 0" :application="applications[0]" />
    <TenantEmptyAccountModal v-else />
  </template>
  <div class="container">
    <h1>{{ t('applications.myApplications') }}</h1>
    <div class="flex gap-1 wrap mt-2">
      <template v-if="applications">
        <EmptyApplications v-if="applications.length === 0" />
        <ImageCard v-for="application in applications" :source="application.image" data-test-id="applicationCard">
          <p class="mb-05">
            <span v-if="application.title">{{ application.title }}</span>
            <span v-else>{{ application.street }}</span>
          </p>
          <p class="light" v-if="application.title">{{ application.street }}</p>
          <p class="light mb-05">
            {{ application.size }} m² | {{ application.bedrooms }} {{ t('common.roomsAbbreviation') }}
          </p>
          <p>{{ application.price }} €/{{ t('common.month') }}</p>
          <div class="applied mt-1" v-if="isApplicationCompleted">{{ t('applications.applied') }}</div>
          <RouterLink
            v-else
            :to="{ name: 'application', params: { id: application.id } }"
            data-test-id="applicationLink"
            @click="track(AnalyticsEvent.TenantApplicationSelectApplication)"
          >
            <Button v-if="mustCompleteDocumentation" class="mt-1" variant="failure">
              {{ t('applications.completeDocumentation') }}
            </Button>
            <Button v-else class="mt-1">{{ t('applications.request') }}</Button>
          </RouterLink>
        </ImageCard>
      </template>
      <template v-else>
        <ImageCardSkeleton />
        <ImageCardSkeleton />
        <ImageCardSkeleton />
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  ApplicationUpdatedModal,
  Button,
  EmptyApplications,
  ImageCard,
  ImageCardSkeleton,
  TenantAccountSuccessModal,
  TenantEmptyAccountModal
} from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { createApplication, findAllApplications } from '@/modules/application/application'
import type { Application } from '@/modules/application/domain/application'
import { findAllTenants, findTenantLead } from '@/modules/tenant/application'
import { isTenantCompleted, isTenantDocumentationCompleted } from '@/modules/tenant/domain/tenant'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'

const { t } = useI18n()
const { track } = useAnalytics()

const applications = ref<Nullable<Application[]>>(null)
const isApplicationCompleted = ref(false)
const mustCompleteDocumentation = ref(false)

track(AnalyticsEvent.TenantApplicationApplicationsList)

async function linkTenantApplication() {
  try {
    const { propertyId } = await findTenantLead()
    if (propertyId) {
      await createApplication(propertyId)
      applications.value = await findAllApplications()
    }
  } catch {}
}

onMounted(async () => {
  const tenants = await findAllTenants()
  isApplicationCompleted.value = tenants.every(isTenantCompleted)
  mustCompleteDocumentation.value =
    tenants.some((tenant) => tenant.employmentStatus !== null) && !tenants.every(isTenantDocumentationCompleted)
  applications.value = await findAllApplications()
  if (applications.value.length === 0) {
    await linkTenantApplication()
  }
})
</script>
<style scoped lang="sass">
p:not(.light)
  font-size: 1rem
  font-weight: bold

button.button
  padding: 1rem
  width: 100%

.applied
  padding: 1rem
  border-radius: 4rem
  background-color: #f0f0f0
  color: #9F9F9F
  text-align: center
</style>
