<template>
  <OnboardingCompletedModal v-if="showOnboardingCompletedModal" />
  <Layout>
    <template #left>
      <PropertyStepper :property="property" :landlord="landlord" />
    </template>
    <template #main>
      <PaymentInformationForm v-model="paymentInformation" />
      <div class="mt-2 flex align-center gap-05" id="termsAndConditionsCheckbox">
        <Checkbox
          v-if="mustAcceptTermsAndConditions"
          v-model="termsAndConditions"
          data-test-id="termsAndConditionsCheckbox"
        />
        <p>
          <span v-if="mustAcceptTermsAndConditions">
            {{ t('property.payment.termsAndConditions.readAndAccept') }}&nbsp;
          </span>
          <a :href="termsAndConditionsUrl" target="_blank">
            {{ t('property.payment.termsAndConditions.link') }}
          </a>
        </p>
      </div>
      <div class="error-message" v-if="showTermsAndConditionsError">
        {{ t('property.payment.termsAndConditions.error') }}
      </div>
    </template>
    <template #footer>
      <PropertyStepper mobile :property="property" :landlord="landlord" />
      <OnboardingFooter
        v-if="!showOnboardingCompletedModal"
        @continue="onContinue"
        @exit="onExit"
        :step="6"
        :isLoading="loading"
        :isCompleted="isOnboardingCompleted"
      />
    </template>
  </Layout>
</template>
<script lang="ts" setup>
import {
  Checkbox,
  Layout,
  OnboardingCompletedModal,
  OnboardingFooter,
  PaymentInformationForm,
  PropertyStepper
} from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { findCurrentLandlord } from '@/modules/landlord/application'
import type { Landlord } from '@/modules/landlord/domain/landlord'
import { hasLandlordDocuments } from '@/modules/landlord/domain/landlordDocuments'
import {
  findPropertyById,
  finishPropertyOnboarding,
  updatePropertyPaymentInformation
} from '@/modules/property/application'
import { isPropertyComplete, type Property } from '@/modules/property/domain/property'
import type { PropertyPaymentInformation } from '@/modules/property/domain/propertyPaymentInformation'
import { router } from '@/router'
import { daysBetween } from '@/utils/date'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useAnalytics()

const id = router.currentRoute.value.params.id as string
const landlord = ref<Landlord | undefined>()
const property = ref<Property | undefined>()
const paymentInformation = ref<PropertyPaymentInformation | undefined>()
const loading = ref(false)
const showOnboardingCompletedModal = ref(false)
const termsAndConditions = ref(false)
const showTermsAndConditionsError = ref(false)
const termsAndConditionsUrl = import.meta.env.VITE_LANDLORD_TERMS_AND_CONDITIONS_URL

track(AnalyticsEvent.LandlordPropertyRegisterStartPlan)

const mustAcceptTermsAndConditions = computed(
  () => landlord.value && daysBetween(landlord.value.createdAt, new Date()) >= 1
)

const isOnboardingCompleted = computed(() => {
  if (!paymentInformation.value || !property.value || !landlord.value) {
    return false
  }

  return (
    hasLandlordDocuments(landlord.value) &&
    isPropertyComplete({ ...property.value, paymentInformation: paymentInformation.value })
  )
})

onMounted(async () => {
  landlord.value = await findCurrentLandlord()
  property.value = await findPropertyById(id)
  paymentInformation.value = { ...property.value.paymentInformation }
})

async function save() {
  if (!paymentInformation.value || !property.value) return

  loading.value = true
  try {
    await updatePropertyPaymentInformation(id, paymentInformation.value)
    track(AnalyticsEvent.LandlordPropertyRegisterSubmitPlan)
  } catch {
    loading.value = false
  }
}

async function onExit() {
  await save()
  await router.push({ name: 'properties' })
}

async function onContinue() {
  await save()

  if (isOnboardingCompleted.value) {
    if (mustAcceptTermsAndConditions.value && !termsAndConditions.value) {
      loading.value = false
      showTermsAndConditionsError.value = true
      scrollToTermsAndConditions()
      return
    }

    await finishPropertyOnboarding(id)
    track(AnalyticsEvent.LandlordOnboardingCompleted)
    showOnboardingCompletedModal.value = true
  } else {
    await router.push({ name: 'resumeOnboarding' })
  }
}

function scrollToTermsAndConditions() {
  const element = document.getElementById('termsAndConditionsCheckbox')
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}
</script>
