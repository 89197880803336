export const esMessages = {
  application: {
    documentation: {
      description:
        'Para continuar con el proceso, necesitamos que subas la documentación de todos los inquilinos que vivirán en la propiedad. Una vez que hayas enviado todos los documentos, los revisaremos para validar su solvencia. Nos pondremos en contacto contigo por teléfono para informarte si todo está correcto.',
      pending: 'Pendientes',
      documents: 'Documentos',
      title: 'Documentación requerida de inquilinos'
    },
    documentationCompleted: {
      bold: 'Has enviado con éxito tu solicitud para una de las propiedades de Vivara.',
      finish: 'Llévame a mi página de solicitudes',
      main: 'Ahora revisaremos la información y te informaremos con los pasos a seguir.'
    },
    requiredDocumentation: 'Documentación necesaria',
    saveAndExit: 'Guardar y salir',
    send: 'Enviar'
  },
  applications: {
    accountSuccess: {
      title: '¡Cuenta creada con éxito!',
      description:
        'Tu cuenta Vivara ha sido creada con éxito. Has realizado una solicitud para una de nuestras propiedades. Para finalizar esta solicitud, debes subir la documentación necesaria para verificar la solvencia de todos los inquilinos.',
      question: '¿Quieres continuar con este proceso?'
    },
    accountSuccessEmpty: {
      main: 'Tu cuenta en Vivara ha sido creada con éxito.',
      bold: 'Pronto recibirás un email de confirmación con más detalles.'
    },
    applied: 'Solicitado',
    completeDocumentation: 'Completar documentación',
    completeLater: 'Completar más tarde',
    empty:
      'Actualmente, no tienes ninguna solicitud realizada para propiedades de Vivara. Puedes explorar las propiedades en alquiler de Vivara y realizar una solicitud a través de los siguientes enlaces:',
    myApplications: 'Mis solicitudes',
    request: 'Solicitar'
  },
  auth: {
    logOut: 'Cerrar sesión',
    privacyPolicy: 'Política de Privacidad',
    signIn: 'Accede a tu cuenta',
    signInWithGoogle: 'Continuar con Google',
    signInWithFacebook: 'Continuar con Facebook',
    signUp: 'Registro',
    signUpPrompt: '¿No tienes cuenta?',
    signUpLink: 'Crea una cuenta',
    termsAndConditions: 'Términos y Condiciones',
    welcome: 'Bienvenido/a a Vivara'
  },
  authenticationError: {
    invalidCredentials: 'El correo electrónico o la contraseña son incorrectos',
    userAlreadyExists: 'Ya existe una cuenta asociada a ese correo electrónico'
  },
  common: {
    accept: 'Aceptar',
    address: 'Dirección',
    and: 'y',
    cancel: 'Cancelar',
    catastralReference: 'Referencia catastral',
    congratulations: '¡Enhorabuena!',
    continue: 'Continuar',
    delete: 'Borrar',
    floor: 'Planta',
    month: 'mes',
    or: 'o',
    property: {
      availability: {
        exactDate: 'Conozco la fecha exacta',
        less1month: 'Menos de un mes',
        oneTo3months: 'De 1 a 3 meses',
        more3months: 'Más de 3 meses'
      },
      bathrooms: 'Baños',
      bedrooms: 'Dormitorios',
      type: {
        flat: 'Piso',
        penthouse: 'Ático',
        duplex: 'Duplex',
        attachedHouse: 'Adosado',
        detachedHouse: 'Casa'
      },
      listed: 'Registrada',
      indoorExtras: {
        fridge: 'Nevera',
        oven: 'Horno',
        microwave: 'Microondas',
        stove: 'Vitrocerámica',
        dishwasher: 'Lavavajillas',
        washingMachine: 'Lavadora',
        dryer: 'Secadora',
        alarm: 'Alarma',
        airConditioning: 'Aire acondicionado'
      },
      newProperty: 'Nueva propiedad',
      outdoorExtras: {
        lift: 'Ascensor',
        terrace: 'Terraza',
        balcony: 'Balcón',
        privateGarage: 'Garaje privado',
        communityGarage: 'Garaje comunitario',
        storageRoom: 'Trastero'
      },
      pricingPlan: {
        peaceOfMind: 'Plan Tranquilidad',
        secured: 'Plan Cobro Asegurado'
      },
      state: {
        new: 'Nuevo',
        veryGood: 'Muy bien',
        good: 'Bien',
        needsRefurbish: 'Para reformar'
      },
      utilities: {
        electricity: 'Electricidad',
        internet: 'WiFi',
        heating: 'Calefaccción',
        insurance: 'Seguro de hogar',
        water: 'Agua'
      }
    },
    save: 'Guardar',
    search: 'Buscar',
    showLess: 'Mostrar menos',
    showMore: 'Mostrar más',
    signIn: 'Accede',
    rooms: 'Habitación',
    roomsAbbreviation: 'hab.'
  },
  forms: {
    email: 'Correo electrónico',
    emailPlaceholder: "ejemplo{'@'}correo.es",
    fileInputBrowse: 'elige',
    fileInputFile: 'Arrastra y suelta ficheros o',
    fileInputImage: 'Arrastra y suelta imágenes o',
    fileInputMax: 'ficheros máximo',
    fileInputMaxSize: 'Tamaño máximo {0}MB',
    fullName: 'Nombre y apellidos',
    fullNamePlaceholder: 'Tu nombre y apellidos',
    name: 'Nombre',
    namePlaceholder: 'Tu nombre',
    password: 'Contraseña',
    passwordPlaceholder: 'Contraseña',
    phone: 'Número de teléfono',
    phonePlaceholder: 'Tu número de teléfono',
    surname: 'Apellidos',
    surnamePlaceholder: 'Tus apellidos'
  },
  genericError: {
    unexpected: 'Algo salió mal'
  },
  landlord: {
    documents: {
      description: {
        id: '(documento de identidad)'
      },
      type: {
        id: 'NIF/NIE'
      },
      uploadInstructions: 'Formatos admitidos: pdf, png, jpg.'
    }
  },
  signin: {
    recoverPassword: {
      description: 'Te enviaremos un email con un enlace para que puedas restablecer tu contraseña.',
      link: '¿Has olvidado tu contraseña?',
      title: 'Restablecer contraseña',
      send: 'Restablecer contraseña',
      success: {
        title: 'Solicitud realizada con éxito',
        description: `Hemos recibido correctamente tu solicitud para restablecer tu contraseña. 
          
          En breve recibirás un email para terminar el proceso. 
          
          ¿No has recibido tu email? Revisa tu carpeta de spam para asegurarte de que el email no está ahi.`
      }
    },
    resetPassword: {
      description: 'Tu nueva contraseña debe ser diferente de las contraseñas utilizadas anteriormente.',
      password: 'Nueva contraseña',
      passwordPlaceholder: 'Agrega una nueva contraseña',
      passwordConfirmation: 'Confirma la nueva contraseña',
      passwordConfirmationPlaceholder: 'Vuelve a escribir la contraseña',
      save: 'Guardar la nueva contraseña',
      title: 'Cambiar contraseña'
    }
  },
  signup: {
    account: {
      commercial:
        'Quiero recibir comunicaciones comerciales y ofertas de Vivara o de terceros adaptadas a mis intereses.',
      signInPrompt: '¿Ya tienes una cuenta?',
      signUpWithEmail: 'Regístrate con correo electrónico',
      step: 'Información de tu cuenta',
      terms: 'Acepto los',
      termsEnd: 'de Vivara Company.',
      title: {
        main: 'Añade la {0}',
        bold: 'información de cuenta.'
      }
    },
    onboarding: {
      title: 'Crear cuenta'
    },
    personal: {
      step: 'Tus datos personales',
      title: {
        main: 'Añade tus {0}',
        bold: 'datos personales.'
      }
    },
    property: {
      step: 'Datos de tu propiedad'
    },
    type: {
      aLandlord: 'Soy propietario',
      aLandlordSub: 'Estoy buscando inquilino(s) para mi propiedad',
      aTenant: 'Soy inquilino',
      aTenantSub: 'Estoy buscando una propiedad para alquilar',
      step: 'Tipo de usuario',
      title: {
        main: '¿Eres {0}',
        bold: 'propietario o inquilino?'
      }
    },
    welcome: 'Bienvenido/a a Vivara.'
  },
  profile: {
    password: {
      changePassword: 'Cambiar contraseña',
      confirmNewPassword: 'Confirma la nueva contraseña',
      confirmNewPasswordPlaceholder: 'Vuelve a escribir la contraseña',
      description: 'Tu nueva contraseña debe ser diferente de las contraseñas utilizadas anteriormente.',
      newPassword: 'Nueva contraseña',
      newPasswordPlaceholder: 'Agrega una nueva contraseña',
      title: 'Cambiar contraseña'
    },
    settings: {
      deleteAccount: 'Eliminar tu cuenta',
      deleteAccountConfirmation: {
        bold: 'Eliminar tu cuenta es una acción permanente.',
        cancel: 'Cancelar y volver',
        confirm: 'Eliminar cuenta',
        description:
          'Esto borrará todos tus datos, y no podrás recuperar tu cuenta ni la información asociada. Si estás seguro de continuar, confirma a continuación.'
      },
      email: 'Correo electrónico de contacto',
      phone: 'Teléfono de contacto',
      save: 'Guardar cambios',
      title: 'Ver mi perfil'
    },
    help: {
      contact: 'Contáctanos',
      description:
        'Si necesitas asistencia, estamos a un clic de distancia. Contáctanos y estaremos encantados de ayudarte con lo que necesites.',
      title: 'Ayuda'
    }
  },
  properties: {
    accountSuccess: {
      main: 'Has completado la creación de tu cuenta.',
      bold: '¿Te gustaría continuar añadiendo las características de tu propiedad para publicarla?'
    },
    addProperty: 'Añadir una propiedad',
    characteristicsSuccess: {
      main: 'Has completado las características de tu propiedad.',
      bold: '¿Te gustaría continuar subiendo la documentación requerida para registrar tu propiedad? '
    },
    completeLater: 'Completar más tarde',
    continueRegistration: 'Continuar con el registro',
    infoBox:
      'No estás seguro de qué renta establecer? No te preocupes. Puedes dejarlo en blanco y nosotros te ayudaremos a estimarlo según las características de tu propiedad.',
    myProperties: 'Mis propiedades',
    myProfile: 'Mi perfil',
    onboardingCompleted: {
      bold: 'Has finalizado con éxito el registro de tu propiedad y seleccionado un Plan para tu propiedad.',
      finish: 'Llévame a mi página de propiedades',
      main: 'Ahora revisaremos la información y te informaremos cuando esté publicada y los pasos a seguir.'
    }
  },
  property: {
    basic: {
      pets: '¿Se aceptan mascotas?',
      petsAllowed: 'Sí, la propieda acepta mascotas',
      petsNotAllowed: 'No, la propiedad no acepta mascotas',
      roomInformation: {
        bathroom: 'Especificaciones del baño',
        bathroomDescription:
          'Se deben contar los inodoros como baño independiente si están ubicados fuera del baño principal.',
        bedroom: 'Especificaciones del dormitorio',
        bedroomDescription: 'Según la ley, una habitación debe tener al menos 5m2 para considerarse dormitorio.'
      },
      rooms: 'Cuántas habitaciones hay en tu propiedad',
      state: 'Selecciona la opción que describa mejor el estado de tu propiedad',
      step: 'Datos generales',
      title: {
        main: 'Por favor, indícanos las características {0}',
        bold: 'básicas.'
      },
      type: 'Elige la opción que mejor describa el tipo de propiedad'
    },
    characteristics: {
      step: 'Características de tu propiedad'
    },
    core: {
      addReference: 'Añadir referencia',
      addressPlaceholder: 'Dirección de la vivienda',
      availabilityDate: 'Fecha de disponibilidad',
      availabilityTitle: {
        main: 'Por favor, indica el primer día en el que tu {0}',
        bold: 'propiedad estará disponible.'
      },
      availabilityType: 'Disponibilidad de la propiedad',
      availabilityTypePlaceholder: 'Seleccionar disponibilidad',
      catastralReferenceError: 'No hemos podido encontrar la propiedad con esta referencia catastral',
      catastralReferenceInformation:
        'El número de referencia catastral es un código de 20 cifras que se encuentra en la Sede Electrónica del Catastro',
      catastralReferencePlaceholder: '1111111XXXXXXX1111XX',
      firstRent: 'Propiedad disponible en alquiler por primera vez',
      floorPlaceholder: 'Planta de la vivienda',
      manualAddressToggle: 'No tengo referencia catastral, introduciré los datos manualmente',
      step: 'Datos de tu propiedad',
      surface: 'Superficie',
      surfacePlaceholder: 'Superficie de la vivienda',
      rentAmount: 'Renta mensual máxima',
      rentTitle: {
        main: '¿Cuál será tu {0}',
        bold: 'renta mensual?'
      },
      usableSurface: 'Superficie útil',
      usableSurfacePlaceholder: 'Superficie útil de la vivienda',
      usableSurfaceToggle: 'Es superficie útil',
      title: {
        main: 'Añade los {0}',
        bold: 'datos de tu propiedad.'
      }
    },
    details: {
      description:
        'Aquí puedes ver la información registrada de tu propiedad en Vivara. Esta vista es solo de lectura. Si deseas realizar modificaciones, por favor contacta con nosotros.',
      documents: {
        title: 'Documentación requerida'
      },
      characteristics: {
        bathrooms: 'Baños',
        bedrooms: 'Dormitorios',
        equipment: 'Equipamientos extras',
        furniture: 'Mobiliario',
        furnitureNo: 'Sin amueblar',
        furnitureYes: 'Amueblado',
        generalData: 'Datos generales',
        indoorExtras: 'Extras interiores',
        outdoorExtras: 'Extras exteriores',
        pets: 'Mascotas',
        petsNo: 'No, la propiedad no acepta mascotas',
        petsYes: 'Sí, la propiedad acepta mascotas',
        propertyType: 'Tipo de propiedad',
        propertyCondition: 'Estado de la propiedad',
        title: 'Características propiedad'
      },
      images: {
        needsPhotoService: 'Servicio fotografía profesional Vivara',
        title: 'Galería de imágenes'
      },
      propertyData: {
        availabilityType: 'Disponibilidad',
        builtSize: 'Superficie útil',
        catastralReference: 'Referencia catastral',
        floor: 'Piso',
        plan: 'Plan Vivara',
        street: 'Dirección',
        title: 'Datos propiedad',
        rent: 'Renta mensual'
      },
      title: 'Información registrada'
    },
    documents: {
      description: {
        energyCertificate: '',
        habitabilityCertificate: '',
        propertyExpenses: '(IBI, Comunidad de propietarios, tasa de residuos, vado, etc)',
        utilityBill: '(agua, luz y gas)'
      },
      step: 'Documentación requerida',
      title: {
        main: 'Por favor, añade los {0}',
        bold: 'documentos requiridos.'
      },
      type: {
        energyCertificate: 'Certificado energético',
        habitabilityCertificate: 'Cédula de habitabilidad',
        propertyExpenses: 'Gastos de la propiedad',
        utilityBill: 'Factura de suministros'
      },
      uploadInstructions: 'Formatos admitidos: pdf, png, jpg.'
    },
    equipment: {
      indoorToggle: 'Estoy alquilando mi propiedad sin extras de interior.',
      indoorTitle: {
        main: 'Añade detalles sobre tus {0}',
        bold: 'extras de interior.'
      },
      furnished: 'Amueblado',
      furnishedSubtitle: 'La propiedad se alquilará con muebles',
      furnitureTitle: {
        main: '¿Cuál es el estado del {0}?',
        bold: 'mobiliario de tu propiedad'
      },
      outdoorToggle: 'Estoy alquilando mi propiedad sin extras de exterior.',
      outdoorTitle: {
        main: 'Añade detalles sobre tus {0}',
        bold: 'extras de exterior.'
      },
      notFurnished: 'Sin amueblar',
      notFurnishedSubtitle: 'La propiedad se alquilará sin muebles',
      step: 'Equipamiento y extras'
    },
    images: {
      professionalPhotoService: 'Estoy interesado en el servicio de fotografía profesional de propiedades de Vivara.',
      step: 'Galería de imágenes',
      title: {
        main: 'Muéstranos toda tu propiedad en su estado actual añadiendo {0}',
        bold: 'imágenes.'
      },
      uploadInstructions: 'Formatos admitidos: png, jpg, jpeg.'
    },
    onboarding: {
      continue: 'Continuar',
      finish: 'Finalizar',
      saveAndExit: 'Guardar y salir',
      title: 'Registra tu propiedad'
    },
    payment: {
      features: {
        tenantReplacement: 'Reemplazo de inquilino gratis los primeros 12 meses: si lo pierdes, te encontramos otro',
        photography: 'Fotografías de calidad',
        tenantSelection: 'Selección inquilino ideal',
        digitalContract: 'Contrato digital',
        automaticMonthlyPayments: 'Pagos mensuales automáticos',
        nonPaymenetCoverage: 'Cobertura impagos (12 meses)',
        evictionCoverage: 'Cobertura legal contra desahucios',
        valdalismCoverage: 'Cobertura actos vandálicos de hasta 3.000€',
        repairManagement: 'Gestión de reparaciones',
        incidentManagement: 'Gestión de incidencias',
        depositManagement: 'Gestión de fianza',
        utilityTransfers: 'Cambio de suministros'
      },
      lessDetails: 'Menos información',
      moreDetails: 'Más información',
      peaceOfMind: {
        description: 'Despreocupate, nosotros nos ocupamos de todo',
        name: 'Plan Tranquilidad',
        price: '1 mes + 8% del alquiler mensual'
      },
      popular: 'El más popular',
      title: {
        main: 'Elige el mejor {0}',
        bold: 'plan para tu propiedad.'
      },
      secured: {
        description: 'Tu propiedad siempre en rentabilidad',
        name: 'Plan Cobro Asegurado',
        price: '1 mes + 5% del alquiler mensual'
      },
      termsAndConditions: {
        error: 'Debes aceptar los Términos y Condiciones antes de finalizar el proceso',
        link: 'Términos y Condiciones',
        readAndAccept: 'He leído y acepto los'
      },
      step: 'Elige tu plan'
    }
  },
  addFirstProperty: {
    intro: 'Hola {name}! ¿Listo para agregar tu primera propiedad?',
    sub: 'Add basic information about your property.',
    addProperty: 'Añadir una propiedad'
  },
  infoFooter: {
    info1: 'Copyright © 2024. Todos los derechos reservados',
    info2: 'Registre d`agents immobiliaris de Catalunya aicat 12678'
  },
  tenant: {
    add: 'Añadir inquilino',
    coTenant: 'Co-inquilino',
    delete: {
      bold: '¿Estás seguro de que deseas borrar la información de este inquilino?',
      cancel: 'Cancelar y volver',
      confirm: 'Eliminar inquilino',
      description:
        'Toda la información y documentos subidos para este inquilino se eliminarán de forma permanente. Si estás seguro, haz clic en “Eliminar inquilino”. De lo contrario, puedes cancelar esta acción y mantener los datos.',
      title: 'Eliminar inquilino'
    },
    documents: {
      employmentContract: 'Contrato laboral',
      id: 'DNI/NIE (por las dos caras)',
      lastForm100: 'Último modelo 100',
      lastForm300: 'Último modelo 300',
      payslip: 'Dos últimas nóminas',
      pensionRevaluationCertificate: 'Certificado de revalorización de pensiones',
      uploadInstructions: 'Formatos admitidos: pdf, png, jpg.'
    },
    employmentStatus: 'Situación laboral',
    employmentStatuses: {
      employed: 'Asalariado',
      selfEmployed: 'Autónomo',
      retired: 'Jubilado'
    },
    employmentStatusDescription:
      'Asegurate de seleccionar tu situación laboral actual para ver los documentos requeridos.',
    mainTenant: 'Inquilino principal',
    requiredDocumentation: {
      employed: 'Documentación requerida para asalariados',
      selfEmployed: 'Documentación requerida para autónomos',
      retired: 'Documentación requerida para jubilados'
    }
  },
  validationError: {
    formatEmail: 'Por favor, introduce un email válido',
    formatPassword: 'La contraseña debe tener entre 6 y 20 caracteres de longitud',
    formatPhone: 'Por favor, introduce un número de teléfono válido',
    invalidDate: 'Por favor, introduce una fecha válida',
    passwordsDoNotMatch: 'Las contraseñas no coinciden',
    required: 'Por favor, rellena esta información',
    requiredEmail: 'El correo electrónico es obligatorio',
    requiredFullName: 'El nombre y los apellidos son obligatorios',
    requiredName: 'El nombre es obligatorio',
    requiredPassword: 'La contraseña es obligatoria',
    requiredSurname: 'Los apellidos son obligatorios',
    requiredTermsAndConditions: 'Debes aceptar los Términos y condiciones'
  },
  propertyOnboardingProgress: {
    title: 'Registra tu propiedad',
    step1: {
      title: 'Datos de tu propiedad'
    },
    step2: {
      title: 'Características de tu propiedad',
      sub1: 'Datos generales',
      sub2: 'Equipamientos y extras',
      sub3: 'Galería de imágenes'
    },
    step3: {
      title: 'Documentación requerida',
      sub1: 'DNI',
      sub2: 'Certificado energético',
      sub3: 'Cédula de habitabilidad',
      sub4: '(IBI)',
      sub5: 'Facturas de suministros'
    },
    step4: {
      title: 'Elige tu plan'
    }
  }
}
